'use strict';

// Play sound and visualize for the HOME
// 2020/11/29

// Sound Data
const soundData = [
    {
        title: 'もえもえ♡うさぎ',
        year: '2010',
        file: '1.mp3',
        loader: null,
        currentTime: 0
    },
    {
        title: 'ヴァイオリン・ソナタ',
        year: '2005',
        file: '2.mp3',
        loader: null,
        currentTime: 0
    },
    {
        title: 'コンポジション',
        year: '2001',
        file: '3.mp3',
        loader: null,
        currentTime: 0
    }
];


$(() => {
    const player = $('#player-home');
    if (!$(player).length) return;

    const wrapper = $(player).closest('.player-wrapper');
    let cw, ch;

    // Set Canvas Size
    const setCanvasSize = () => {
        cw = $(wrapper).width();
        ch = $(wrapper).height();

        $(player)
            .attr('width', cw)
            .attr('height', ch);
    };

    setCanvasSize();
    $(window).on('resize', function () {
        setCanvasSize();
    });

    // Show progress
    let progressData = [];
    const setProgress = (num, loaded, total) => {
        const progress = $('.loader-wrapper .progress');
        progressData[num] = {
            loaded: loaded,
            total: total
        };

        let loadedSum = 0;
        let totalSum = 0;
        progressData.forEach((item, i) => {
            loadedSum += progressData[i].loaded;
            totalSum += progressData[i].total;
        });

        $(progress).text((loadedSum / totalSum * 100).toFixed(0) + '%');

        if (progressData.length === soundData.length &&
            loadedSum > 0 &&
            loadedSum === totalSum) {
            setMessage();
        }
    };

    const setMessage = () => {
        const progress = $('.loader-wrapper .progress');
        $(progress).text('Processing Data...');
    };


    // Context
    const ctx = $(player).get(0).getContext('2d');
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    const Loader = function (url, title, file, num) {
        this.url = url;
        this.title = title;
        this.file = file;
        this.number = num;
        this.buffer = null;
        this.visualizer = null;
    };

    Loader.prototype.loadBuffer = function () {
        let loader = this;

        // Get Data
        fetch(loader.url, {
            method: 'GET',
        })
            .then(async response => {
                const buf = response.clone().arrayBuffer();

                // 全バイト数を先に取得
                const total = parseInt(response.headers.get("Content-Length"));

                // 受信したバイト数
                let loaded = 0;
                const reader = response.body.getReader();
                while (true) {
                    const {done, value} = await reader.read();
                    if (done) {
                        break;
                    }
                    // 読んだデータはバイナリデータ（Uint8Array）で与えられる
                    loaded += value.length;
                    setProgress(this.number, loaded, total);
                }

                return buf;
            })
            .then(audioData => {

                // 取得したデータをデコードする
                audioCtx.decodeAudioData(audioData,
                    buffer => {
                        if (!buffer) {
                            throw new Error('Error: No buffer');
                        }

                        // Save the decoded data
                        buffer.title = loader.title;
                        buffer.file = loader.file;
                        loader.saveBuffer(buffer);
                    },
                    e => {
                        throw new Error('Error with decoding audio data: ' + e.err);
                    });
            })
            .catch(e => {
                console.log(e);
            });
    };

    Loader.prototype.saveBuffer = function (buffer) {
        this.buffer = buffer;
        this.resetElapsed();
        console.log(this.buffer);
    }

    Loader.prototype.drawVisualizer = function () {
        this.resetVisualizer();
        this.visualizer = new Visualizer(this.buffer);
    };

    Loader.prototype.playSound = function () {
        this.resetVisualizer();
        this.visualizer = new Visualizer(this.buffer);
        this.visualizer.play();
    };

    Loader.prototype.stopSound = function () {
        if (this.visualizer !== null) {
            this.visualizer.stop();
        }

        // Save elapsed time to the buffer
        this.buffer.elapsed = this.visualizer.getElapsed();
    };

    Loader.prototype.resetElapsed = function () {
        this.buffer.elapsed = 0;
    };

    Loader.prototype.resetVisualizer = function () {
        if (this.visualizer !== null) {
            this.visualizer = null;
        }
    };


    /**
     * Visualizer
     * @param buffer
     */
    const Visualizer = function (buffer) {
        this.offset = buffer.elapsed;
        this.startedAt = 0;
        this.elapsed = 0;

        // Volume
        this.gainNode = audioCtx.createGain();
        this.gainNode.gain.value = 1.0;

        this.sourceNode = audioCtx.createBufferSource();
        this.sourceNode.buffer = buffer;
        this.analyserNode = audioCtx.createAnalyser();

        this.times = new Uint8Array(this.analyserNode.frequencyBinCount);
        this.sourceNode.connect(this.analyserNode);
        this.analyserNode.connect(this.gainNode);
        this.gainNode.connect(audioCtx.destination);

        this.draw();

        this.sourceNode.onended = () => {
            // Play Next automatically
            const current = (Date.now() - this.startedAt) / 1000;
            if (current >= Math.floor(this.sourceNode.buffer.duration)) {
                $(skip).trigger('click');
            }
        };
    }

    Visualizer.prototype.play = function () {
        this.sourceNode.start(0, this.offset / 1000);
        this.startedAt = Date.now() - this.offset;
    };

    Visualizer.prototype.stop = function () {
        this.sourceNode.stop();
        this.elapsed = Date.now() - this.startedAt;
    }

    Visualizer.prototype.getElapsed = function () {
        return this.elapsed;
    };

    Visualizer.prototype.draw = function () {
        // 0~1まで設定でき、0に近いほど描画の更新がスムーズになり, 1に近いほど描画の更新が鈍くなる。
        this.analyserNode.smoothingTimeConstant = 0.1;

        // FFTサイズを指定する。デフォルトは2048。
        this.analyserNode.fftSize = 2048;

        // 時間領域の波形データを引数の配列に格納するメソッド。
        // analyserNode.fftSize / 2の要素がthis.timesに格納される。今回の配列の要素数は1024。
        this.analyserNode.getByteTimeDomainData(this.times);

        // 全ての波形データを描画するために、一つの波形データのwidthを算出する。
        // const barWidth = cw / this.analyserNode.frequencyBinCount;
        const barWidth = 2;

        // 描画クリア
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillStyle = 'rgba(255, 255, 255, 1)';
        ctx.fillRect(0, 0, cw, ch);
        ctx.globalCompositeOperation = 'source-over';

        // analyserNode.frequencyBinCountはanalyserNode.fftSize / 2の数値。よって今回は1024。
        for (let i = 0; i < this.analyserNode.frequencyBinCount; i++) {
            let value = this.times[i]; // 波形データ 0 ~ 255までの数値が格納されている。
            const percent = value / 255; // 255が最大値なので波形データの%が算出できる。

            const barHeight = Math.random() * 5.5 + 6.5; // 波形データ高さ
            const height = ch * percent; // %に基づく高さを算出

            const x = i * barWidth * 2 + 2;
            const offset = ch - height - barHeight / 2; // y座標の描画開始位置を算出

            ctx.fillStyle = 'rgba(45, 185, 189, .65)';
            ctx.fillRect(x, offset, barWidth, barHeight);

            if (x >= cw) break;
        }

        window.requestAnimationFrame(this.draw.bind(this));
    };


    // Sound Controller
    const controller = $(wrapper).find('.control-wrapper');
    const playAndStop = $(controller).find('.play-button');
    const skip = $(controller).find('.skip-button');
    const title = $(controller).find('.title');
    const loader = $(wrapper).find('.loader-wrapper');

    $(() => {
        let current = 0;
        let playing = false;

        const play = () => {
            setTitle();
            $(playAndStop).addClass('stop');
            soundData[current].loader.playSound();
            playing = true;
        };

        const stop = () => {
            soundData[current].loader.stopSound();
            playing = false;
        };

        const playNext = () => {
            if (playing) {
                stop();
            }

            soundData[current].loader.resetElapsed();

            current ++;
            if (current >= soundData.length) {
                current = 0;
            }

            play();
        };

        const setTitle = () => {
            let songInfo = soundData[current].title;
            songInfo += '<span class="year">[' + soundData[current].year + ']</span>';
            $(title).html(songInfo);
        };


        // Loading action
        const showController = () => {
            setTitle();
            soundData[current].loader.drawVisualizer();

            $(player).removeClass('hide');
            $(controller).removeClass('hide');

            $(loader)
                .removeClass('show')
                .on('transitionend', function () {
                    if (!$(this).hasClass('show')) {
                        $(this).css('left', '-9999px');
                    }
                });
        };

        const checkReady = () => {
            let loadedFlag = true;

            for (let i = 0; i < soundData.length; i++) {
                if (soundData[i].loader.buffer === null) {
                    loadedFlag = false;
                }
            }

            if (loadedFlag) {
                console.log('Loaded / Ready');
                showController();
            } else {
                setTimeout(() => {
                    checkReady();
                }, 50);
            }
        };

        const load = () => {
            for (let i = 0; i < soundData.length; i++) {
                soundData[i].loader = new Loader(
                    '/swf/' + soundData[i].file,
                    soundData[i].title,
                    soundData[i].file,
                    i
                );
                soundData[i].loader.loadBuffer();
            }

            checkReady();
        };


        // Play and Stop
        $(playAndStop).on('click', function () {
            if ($(this).hasClass('stop')) {
                stop();
                $(this).removeClass('stop');
            } else {
                play();
                $(this).addClass('stop');
            }
        });

        // Skip to the next
        $(skip).on('click', function () {
            playNext();
        });


        load();
    });
});
