'use strict';

// Play sound and visualize for the header
// 2020/11/21

$(() => {
    const player = $('#player');
    if (!$(player).length) return;

    const wrapper = $(player).closest('.player-wrapper');
    let cw, ch;

    // Set Canvas Size
    const setCanvasSize = () => {
        cw = $(wrapper).width();
        ch = $(wrapper).height();

        $(player)
            .attr('width', cw)
            .attr('height', ch);
    };

    setCanvasSize();
    $(window).on('resize', function () {
        setCanvasSize();
    });


    // Context
    const ctx = $(player).get(0).getContext('2d');
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    const Loader = function (url) {
        this.url = url;
    };

    Loader.prototype.loadBuffer = function () {
        let loader = this;

        // Get Data
        fetch(loader.url, {
            method: 'GET',
        })
            .then(response => response.arrayBuffer())
            .then(audioData => {

                // 取得したデータをデコードする
                audioCtx.decodeAudioData(audioData,
                    buffer => {
                        if (!buffer) {
                            throw new Error('Error: No buffer');
                        }

                        // Play the decoded data
                        $(player).trigger('click');
                        loader.playSound(buffer);
                    },
                    e => {
                        throw new Error('Error with decoding audio data: ' + e.err);
                    });
            })
            .catch(e => {
                console.log(e);
            });
    };

    // 読み込んだ音声データ（バッファ）の再生
    // 波形データ描画開始
    Loader.prototype.playSound = function (buffer) {
        const visualizer = new Visualizer(buffer);
    };


    /**
     * Visualizer
     * @param buffer
     */
    const Visualizer = function (buffer) {
        this.sourceNode = audioCtx.createBufferSource();
        this.sourceNode.buffer = buffer;
        this.analyserNode = audioCtx.createAnalyser();
        this.times = new Uint8Array(this.analyserNode.frequencyBinCount);
        this.sourceNode.connect(this.analyserNode);
        this.analyserNode.connect(audioCtx.destination);

        this.sourceNode.start(0);
        this.draw();
    }

    Visualizer.prototype.draw = function () {
        // 0~1まで設定でき、0に近いほど描画の更新がスムーズになり, 1に近いほど描画の更新が鈍くなる。
        this.analyserNode.smoothingTimeConstant = 0.1;

        // FFTサイズを指定する。デフォルトは2048。
        this.analyserNode.fftSize = 2048;

        // 時間領域の波形データを引数の配列に格納するメソッド。
        // analyserNode.fftSize / 2の要素がthis.timesに格納される。今回の配列の要素数は1024。
        this.analyserNode.getByteTimeDomainData(this.times);

        // 全ての波形データを描画するために、一つの波形データのwidthを算出する。
        // const barWidth = cw / this.analyserNode.frequencyBinCount;
        const barWidth = 2;

        // 描画クリア
        ctx.fillStyle = 'rgba(255, 255, 255, 1)';
        ctx.fillRect(0, 0, cw, ch);

        // analyserNode.frequencyBinCountはanalyserNode.fftSize / 2の数値。よって今回は1024。
        for (let i = 0; i < this.analyserNode.frequencyBinCount; i++) {
            let value = this.times[i]; // 波形データ 0 ~ 255までの数値が格納されている。
            const percent = value / 255; // 255が最大値なので波形データの%が算出できる。

            const barHeight = Math.random() * 5.5 + 6.5; // 波形データ高さ
            const height = ch * percent; // %に基づく高さを算出

            const x = i * barWidth * 2 + 2;
            const offset = ch - height - barHeight / 2; // y座標の描画開始位置を算出

            ctx.fillStyle = 'rgba(45, 185, 189, .65)';
            ctx.fillRect(x, offset, barWidth, barHeight);

            if (x >= cw) break;
        }

        window.requestAnimationFrame(this.draw.bind(this));
    };


    const loader = new Loader('/swf/pon.mp3');
    loader.loadBuffer();
});





