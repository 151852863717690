'use strict';


// Functions for KOMORI Toshiaki
// 2020/07/10
// 2020/11/21

let pc = false;
let sp = false;

if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    sp = true;
} else {
    pc = true;
}

$(() => {
    // Mouse action
    const buttons = $('' +
        'div.control-wrapper button, ' +
        'div.content.archives div.entry-content a, ' +
        'div.content.post-detail div.entry-content a, ' +
        '.comments-link, ' +
        'a.page, a.previouspostslink, a.nextpostslink, a.first, a.last, ' +
        'a.menu, a.home-profile, a.lesson, a.button, a.back, a.page-top, ' +
        'input.wpcf7-submit, input#submit');

    if (pc) {
        $(buttons)
            .on('mouseover', function () {
                $(this).addClass('on');
            })
            .on('mouseout', function () {
                $(this).removeClass('on');
            });
    } else {
        $(buttons)
            .on('touchstart', function () {
                $(this).addClass('on');
            })
            .on('touchend', function () {
                $(this).removeClass('on');
            });
    }


    // Side bar
    // if ($('.sidebar').length) {
    //     var menuImg = new Image();
    //     menuImg.src = '/img/menu_main.svg';
    //     $(menuImg).on('load', function () {
    //         console.log('loaded');
    //         $('.sidebar').addClass('loaded');
    //     });
    // }


    // Page Top
    $('a.page-top').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 400, 'easeOutQuint');
        return false;
    });


    /* ARCHIVES */
    /* NEWS & INFO. */
    /* BLOG */
    // _blank
    if ($('div.content.archives, div.content.post-detail').length) {
        $('div.content div.entry-content a, span.fn a.url').each(function () {
            var rel = '';
            if (typeof $(this).attr('rel') !== 'undefined') {
                rel = $(this).attr('rel') + ' ';
            }

            $(this)
                .attr('target', '_blank')
                .attr('rel', rel + 'noopener');
        });
    }

    /*
        NEWS & INFO.
        BLOG
        コメント文言修正
        コメントにも必須マーク追加
    */
    // 翻訳抜け（と思われる）箇所の訂正
    if ($('h2#comments-title').length) {
        let html = $('h2#comments-title').html();
        html = html.toLowerCase();
        html = html.replace(/one/, '1');
        html = html.replace(/thought(s*)/, 'comment$1');
        $('h2#comments-title').html(html);
    }

    // コメントタイトル変更
    if ($('#respond #reply-title').length) {
        let html = $('#respond #reply-title').html();
        html = html.replace(/コメントを残す/, 'コメントを投稿');
        $('#respond #reply-title').html(html);
    }

    $('#respond .comment-notes')
        .html('<span id="email-notes">E-mailアドレスは公開されません。</span><span class="required">*</span>&nbsp;が付いている入力欄は必須項目です。');

    $('p.comment-form-comment label[for=comment]')
        .append('&nbsp;<span class="required">*</span>');

    $('p.comment-form-email label[for=email]')
        .html('E-mailアドレス&nbsp;<span class="required">*</span>');

    $('p.comment-form-url label[for=url]').html('URL');


    /* CONTACT */
    // checkbox用labelをcheckbox直後に移動
    $('input#acceptance').after($('label.acceptance-label'));


    /* GDPR Cookie Consent */
    // 挙動が唐突な箇所の調整
    // Cookie settings
    $('.cli_settings_button').on('click', function () {
        $('.cli-settings-overlay').addClass('show');
    });

    // modal
    $('.cli-settings-overlay').on('transitionend', function () {
        var opacity = parseFloat($(this).css('opacity'));
        if (opacity === 0) {
            $('#cliSettingsPopup').removeClass('cli-blowup');
            $(this).removeClass('show');
        }
    });


    // WORKS
    /* Media Player - 音量調節 */
    // Prevent opening blank window
    $('.mejs-horizontal-volume-slider').on('click', function () {
        return false;
    });
});