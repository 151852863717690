'use strict';

// Import Libraries
require('jquery.easing');
import '@babel/polyfill';


// modules
import './modules/_common';
import './modules/_sound_home';
import './modules/_sound_header';